@tailwind base;
@tailwind components;
@tailwind utilities;
*{

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F6F8FD;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.debug {
  border: 1px solid red;
}

.debug > * {
  border: 1px solid green;
}

.debug > * > * {
  border: 1px solid blue;
}

element.style {
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #676a6e !important;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.nav-link:hover {
  color: #5a6b85 !important;
}

.globaltwobottomcardd{
  @media screen and (max-width: 1330px){
    display: flex !important;
    flex-direction: column !important;    
  }
}