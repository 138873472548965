@media screen and (max-width: 640px) {
    .__map{
        width: 300px !important;
        height: 130px !important;
    }
      }

@media screen and (max-width: 980px) and (min-width: 640px){
    .__map{
        width: 590px !important;
        height: 245px !important;
        margin-left: 1rem !important;
        margin-bottom: 0.7rem !important;
    }
      }
.ContentLogin {
    background: white;
    width: 100%;
    position:relative;
    margin: auto;
    padding: 20px;
}

.map {
    width: 100%;
}

.iconsTitle {
    display: flex;
}

.content {
    display: flex;
    flex-direction: column;
    /* padding-left: 5em;
    padding-right: 5em; */
    justify-content: center;
}

.inputText {
    height: 3em;
    width: 100%;
    border: 1px solid #e6e6e6;
}

.inputs {
    padding: 3em 10em;
}

.checkBoxInput {
    display: flex;
}

.btnLogin {
    width: 100%;
    height: 3em;
    background: #18B6C0;
    color: white;
    text-align: center;
}
