
@media only screen and (max-width: 576px) {
    .i-test {
      /* background-color: cyan; */
        /* width: 1200px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) */
        padding: 0;
        padding-top:2em;
        /* background-color: aqua; */
    }
  }

  /* .ContentLogin{
      min-height: 95vh;
  } */