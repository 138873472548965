.dark-bleu {
  color: #36708e;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}


.header{

}

#customChart{
  height: 100%;
}
/* .__title{
  font-size: 12px;
  color: red;
} */

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .assainissement__charts{
    grid-template-columns: 1fr 1fr;
  }
  
}

@media screen and (max-width: 768px) {
  .assainissement__charts{
    grid-template-columns: 1fr;
  }
  
}

@media screen and (max-width: 534px){
  .__title{
    font-size: 1rem !important;
  }
  .col__select{
    margin-right: 1.6rem;
  }
  .map__legend{
    display: none;
  }
}
.card__header{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card__header .card__select{
  width: 115px;
}